import {authUserFailureAction} from "../../redux/actions/index";
import {loadNamesAction} from "../private_data/private_data_actions";
import ActionTypes from "./employees_action_types";
import {fetchEmployees} from "./employees_api";

const loadEmployeesRequestAction = () => ({
    type: ActionTypes.EMPLOYEES_REQUEST
});

const loadEmployeesSuccessAction = (payload) => ({
    type: ActionTypes.EMPLOYEES_SUCCESS,
    payload
});

const loadEmployeesFailureAction = (error) => ({
    type: ActionTypes.EMPLOYEES_FAILURE,
    error
});

/**
 * load employees
 * @param {string} organizationId
 * @return {AnyAction}
 */
function loadEmployeesAction(organizationId) {
    return function (dispatch) {
        dispatch(loadEmployeesRequestAction());

        fetchEmployees(organizationId)
            .then(({data}) => {
                dispatch(loadEmployeesSuccessAction(data.data));

                // load names
                dispatch(loadNamesAction("practitioner", Object.keys(data.data), false));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch employees error"}));
                } else {
                    dispatch(loadEmployeesFailureAction(error));
                }
            });
    };
}
export {loadEmployeesAction};
